<template>
    <div class="container">
        <div class="page-header">
            <el-page-header title="返回" content="编辑笔记" @back="goBack" />
            <div class="header-op">
                <div class="header-op-item">
                    <el-button type="success" size="default" @click="submitForm({name, content})">
                        保存
                    </el-button>
                </div>
                <div class="header-op-item">
                    <el-button size="default" @click="histories">
                        历史版本
                    </el-button>
                </div>
                <div class="header-op-item">
                    <el-dropdown trigger="click">
                        <span>
                            <svg class="icon" width="20" height="20" viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg"
                                 data-v-365b8594=""><path fill="currentColor"
                                                          d="M176 416a112 112 0 100 224 112 112 0 000-224m0 64a48 48 0 110 96 48 48 0 010-96zm336-64a112 112 0 110 224 112 112 0 010-224zm0 64a48 48 0 100 96 48 48 0 000-96zm336-64a112 112 0 110 224 112 112 0 010-224zm0 64a48 48 0 100 96 48 48 0 000-96z"></path></svg>
                        </span>
                        <template #dropdown>
                            <el-dropdown-menu>
                                <el-dropdown-item @click="copyNoteLink">
                                    <el-icon><Link /></el-icon>复制内部链接
                                </el-dropdown-item>
                            </el-dropdown-menu>
                        </template>
                    </el-dropdown>
                </div>
            </div>
            <div class="mb-header-op">
                <div class="header-op-item">
                    <el-button type="success" @click="submitForm({name, content})">
                        保存
                    </el-button>
                </div>
                <div class="header-op-item">
                    <el-dropdown trigger="click">
                        <span>
                            <svg class="icon" width="20" height="20" viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg"
                                 data-v-365b8594=""><path fill="currentColor"
                                                          d="M176 416a112 112 0 100 224 112 112 0 000-224m0 64a48 48 0 110 96 48 48 0 010-96zm336-64a112 112 0 110 224 112 112 0 010-224zm0 64a48 48 0 100 96 48 48 0 000-96zm336-64a112 112 0 110 224 112 112 0 010-224zm0 64a48 48 0 100 96 48 48 0 000-96z"></path></svg>
                        </span>
                        <template #dropdown>
                            <el-dropdown-menu>
                                <el-dropdown-item @click="copyNoteLink">
                                    <el-icon><Link /></el-icon>复制内部链接
                                </el-dropdown-item>
                                <el-dropdown-item @click="histories">
                                    <el-icon><DocumentCopy /></el-icon>历史版本
                                </el-dropdown-item>
                            </el-dropdown-menu>
                        </template>
                    </el-dropdown>
                </div>
            </div>
        </div>
        <div id="editor">
            <div id="preview">
                <content-show :content="preContent"/>
            </div>
            <div id="input">
                <note-form @name-change="onNameChange"
                           @content-change="onContentChange"
                           @submit-note="submitForm"
                           :id="id"
                           :name="name"
                           :content="content"/>
            </div>
        </div>
    </div>
</template>

<script>
    import { defineComponent } from 'vue';
    import {ElMessage, ElMessageBox} from "element-plus";
    import {useRouter} from "vue-router";
    import ContentShow from '@/components/notes/ContentShow.vue';
    import NoteForm from "@/components/notes/NoteForm";
    import useMarkdownLinkCopy from '@/composables/useMarkdownLinkCopy';
    import {MARKDOWN_ENGINE_BUILD_IN} from "@/constants/note";
    import {FILE_TYPE_NOTE} from "@/constants/file-type";
    import {NOTES} from "@/constants/api";

    export default defineComponent({
        name: "Edit",
        components: {
            ContentShow,
            NoteForm
        },
        setup() {
            const router = useRouter();
            const goBack = () => {
                router.back();
            }

            const {copyMarkdownLink} = useMarkdownLinkCopy();

            return {
                goBack,
                copyMarkdownLink
            }
        },
        data() {
            return {
                id: '',
                name: '',
                content: '',
                preContent: '',
                isSaved: true
            }
        },

        created() {
            this.id = this.$route.params.noteId;
        },

        mounted() {
            this.get(this.$route.params.noteId);
        },
        async beforeRouteLeave(to, from) {
            if (!this.isSaved) {
                const answer = await ElMessageBox.confirm(
                    '内容还没保存！确定离开吗？',
                    '内容还没保存！',
                    {
                        confirmButtonText: '确定离开',
                        cancelButtonText: '暂不离开',
                        type: 'warning',
                    }
                );
                if (!answer) return false
            }
        },
        methods: {
            histories() {
                this.$router.push({name: 'noteHistories', params: {noteId: this.$route.params.noteId}});
            },
            get(noteId) {
                const vm = this;
                const url = NOTES + '/' + noteId;
                this.axios.get(url, {
                    params: {
                        teamId: this.$store.state.currentTeam?.id
                    }
                }).then(function(response) {
                    vm.$data.name = response.data.note.name;
                    vm.$data.content = response.data.note.content;
                    vm.$data.preContent = response.data.note.content;
                }).catch(function (error) {
                    const data = error.response.data;
                    if (data != null) {
                        ElMessage({
                            showClose: true,
                            message: error.response.data.error,
                            type: 'error',
                        });
                    } else {
                        ElMessage({
                            showClose: true,
                            message: '获取数据失败',
                            type: 'error',
                        });
                    }
                })
            },
            onNameChange(name) {
                this.name = name;
                this.isSaved = false;
            },
            async onContentChange(content) {
                this.content = content;
                this.isSaved = false;
                setTimeout(() => {
                    this.preContent = this.content;
                }, 500)
            },
            submitForm({name, content}) {
                const vm = this
                this.axios.patch(NOTES, {
                    name: name,
                    content: content,
                    id: this.$route.params.noteId,
                    teamId: vm.$store.state.currentTeam?.id
                }).then(function (response) {
                    if (!response) {
                        return
                    }
                    ElMessage({
                        showClose: true,
                        message: '保存成功',
                        type: 'success',
                    });
                    vm.isSaved = true
                }).catch(function (error) {
                    const data = error.response.data;
                    if (data != null) {
                        ElMessage({
                            showClose: true,
                            message: error.response.data.error,
                            type: 'error',
                        });
                    } else {
                        ElMessage({
                            showClose: true,
                            message: '保存失败',
                            type: 'error',
                        });
                    }
                });
            },
            copyNoteLink() {
                if (this.copyMarkdownLink({
                    fileType: FILE_TYPE_NOTE,
                    id: this.$route.params.noteId,
                    name: this.name,
                    engine: MARKDOWN_ENGINE_BUILD_IN
                })) {
                    ElMessage({
                        showClose: true,
                        message: '复制成功',
                        type: 'success'
                    });
                }
            },
        }

    })
</script>

<style scoped lang="less">
    html,
    body,
    #editor {
        margin: 0;
        height: 100%;
        font-family: "Helvetica Neue", Arial, sans-serif;
        color: #333;
    }

    .container {
        margin: 0 8px;
    }

    .page-header {
        display: flex;
        margin-bottom: 16px;
        border-bottom: solid 1px #ebebeb;
        justify-content: space-between;
    }

    .header-op {
        display: flex;
    }

    .el-page-header {
        padding: 16px 8px;
    }

    .mb-header-op {
        display: none;
    }

    .header-op-item {
        margin: auto 5px;
        height: fit-content;
        align-self: center;
    }

    @media only screen and (max-width: 600px) {
        .header-op {
            display: none;
        }
        .mb-header-op {
            display: flex;
        }
    }

    #input,
    #editor #preview {
        display: inline-block;
        width: 49%;
        height: 100%;
        vertical-align: top;
        box-sizing: border-box;
        padding: 0 20px;
        /*text-align: left;*/
    }

</style>