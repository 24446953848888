<template>
    <el-form
            id="note-form"
            ref="ruleForm"
            :model="ruleForm"
            :rules="rules"
            label-width="120px"
            :label-position="'top'"
    >
        <el-input
                v-model="ruleForm.name"
                @input="onNameChange"
                placeholder="标题"
                @focus="onTitleInputFocus"
                @blur="onTitleInputBlur"
                class="note-input"
        ></el-input>
        <div id="editor-menu-bar">
            <el-upload
                    :action="uploadImageUrl + '?noteId=' + id + (currentTeamId ? '&teamId=' + currentTeamId : '')"
                    :disabled="!isEnableImageUpload"
                    :show-file-list="false"
                    :with-credentials="true"
                    :multiple="false"
                    :on-success="handlePictureSuccess"
                    :before-upload="beforePictureUpload"
                    :on-error="handlePictureError"
            >
                <el-icon :size="24" :color="isEnableImageUpload ? '#666666' : '#cccccc'"
                         :class="{'is-loading': uploadingImage}">
                    <Picture v-if="!uploadingImage" />
                    <Loading v-else />
                </el-icon>
            </el-upload>
        </div>
        <el-input
                v-model="ruleForm.content"
                type="textarea"
                :autosize="{ minRows: 18 }"
                resize="vertical"
                @input="onContentChange"
                placeholder="内容"
                @focus="onContentInputFocus"
                @blur="onContentInputBlur"
                ref="contentInput"
                class="note-input">
        </el-input>
        <el-form-item>
            <div style="text-align: center">
                <el-button type="success" @click="submitForm('ruleForm')">
                    保存
                </el-button>
            </div>
        </el-form-item>
    </el-form>
</template>

<script>
    import {computed, defineComponent} from 'vue';
    import {UNTITLED_REG_EXP} from "../../constants/note";
    import {checkImage} from "../../utils/image";
    import {NOTE_UPLOAD_IMAGE} from "../../constants/api";
    import {useStore} from "vuex";

    export default defineComponent({
        name: "NoteForm",
        emits: ['nameChange', 'contentChange', 'submitNote'],
        props: {
            id: Number,
            name: String,
            content: String
        },
        setup() {
            const store = useStore();
            return {
                currentTeamId: computed(() => store.state.currentTeam?.id)
            }
        },
        data() {
            return {
                uploadingImage: false,
                uploadImageUrl: NOTE_UPLOAD_IMAGE,
                isContentInputFocus: false,
                imageUploadEnabled: false,
                untitledNameCopy: null,
                ruleForm: {
                    name: '',
                    content: ''
                },
                rules: {
                    name: [
                        {
                            required: true,
                            message: '请输入标题',
                            trigger: 'blur',
                        }
                    ],
                    content: [
                        {
                            required: true,
                            message: '请输入内容',
                            trigger: 'blur',
                        },
                    ],
                },
            }
        },
        computed: {
            isEnableImageUpload() {
                return this.imageUploadEnabled && this.id != null;
            }
        },
        watch: {
            name(val, oldVal) {
                this.ruleForm.name = val;
            },
            content(val, oldVal) {
                this.ruleForm.content = val;
            },
        },
        methods: {
            onNameChange() {
                this.$emit('nameChange', this.ruleForm.name);
            },
            onContentChange() {
                this.$emit('contentChange', this.ruleForm.content);
            },
            inputPictureText(location) {
                const pictureText = '![图片](' + location + ')';
                const textarea = this.$refs.contentInput.inputOrTextarea;
                const selectionStart = textarea.selectionStart;
                this.ruleForm.content = this.ruleForm.content.substring(0, selectionStart)
                    + pictureText
                    + this.ruleForm.content.substring(selectionStart, this.ruleForm.content.length);
                this.onContentChange();
            },
            handlePictureSuccess(res, file) {
                this.uploadingImage = false;
                this.inputPictureText(res.location);
            },
            handlePictureError(err, file) {
                this.$message.error(JSON.parse(err.message).error);
                this.uploadingImage = false;
            },
            beforePictureUpload(file) {
                const {valid, message} = checkImage(file, 1);
                if (!valid) {
                    this.$message.error(message)
                }
                this.uploadingImage = true;
                return valid;
            },
            onTitleInputFocus() {
                if (UNTITLED_REG_EXP.test(this.ruleForm.name)) {
                    this.untitledNameCopy = this.ruleForm.name;
                    this.ruleForm.name = "";
                }
            },
            onTitleInputBlur() {
                if (this.ruleForm.name === "" && this.untitledNameCopy != null) {
                    this.ruleForm.name = this.untitledNameCopy;
                    this.untitledNameCopy = null;
                }
            },
            onContentInputFocus() {
                this.isContentInputFocus = true;
                this.imageUploadEnabled = true;
            },
            onContentInputBlur() {
                this.isContentInputFocus = false;
                setTimeout(this.disableImageUpload, 500);
            },
            disableImageUpload() {
                if (!this.isContentInputFocus) {
                    this.imageUploadEnabled = false;
                }
            },
            submitForm(formName) {
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        this.$emit('submitNote', { name: this.ruleForm.name, content: this.ruleForm.content });
                    } else {
                        console.log('校验失败')
                        return false
                    }
                })
            }
        }
    })
</script>

<style lang="less">
    #note-form .el-textarea .el-textarea__inner {
        height: 100%;
        border: none;
        border-right: 1px solid #e3e3e3;
        resize: none;
        outline: none;
        background-color: #f6f6f6;
        font-size: 15px;
        font-family: "Monaco", courier, monospace;
        padding: 20px;
    }
</style>

<style scoped lang="less">
    #note-form {
        height: 100%;
    }

    #editor-menu-bar {
        display: flex;
        padding: 0 16px 4px;
        justify-content: flex-start;
    }

    .note-input {
        margin-bottom: 16px;
        font-size: 15px;
        text-align: justify;
    }
</style>